import React from 'react';
import PropTypes from 'prop-types';
import LinkAnimated from './LinkAnimated';
import styled from 'styled-components';

const Container = styled.li`
  margin: 0px 15px;
  display: flex;
`;

const RouteLink = ({ onClick, selected, name }) => (
  <Container>
    <LinkAnimated onClick={onClick} selected={selected}>
      {name}
    </LinkAnimated>
  </Container>
);

RouteLink.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  name: PropTypes.string,
};

export default RouteLink;
