import React, { useState } from 'react';
import { up } from 'styled-breakpoints';
import { useTheme, css } from 'styled-components';
import styled from 'styled-components';

import Section from '../components/Section';
import { Container } from '../components/Container';
import { Button } from '../components/Button';
import Image from '../components/Image';
import { NEWSLETTER_URL } from '../API/ApiConstants';

import PuffLoader from 'react-spinners/PuffLoader';
import { useSnackbar } from 'react-simple-snackbar';
import { successSnackbar, errorSnackbar } from '../shared/SnackbarSettings';
import { TextInput } from '../components/Inputs';
import { isEmail } from '../validators';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '../components/Inputs/ErrorMessage';

const NewsletterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  grid-row-gap: 40px;

  ${up('lg')} {
    margin: 0 auto;
    max-width: 80%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  width: 100%;

  ${up('lg')} {
    min-width: 420px;
  }
`;

const ImageContainer = styled.div`
  overflow: auto;
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-row-gap: 5px;
  align-items: center;
  grid-column-gap: 15px;

  ${up('lg')} {
    grid-template-columns: 67px 1fr;
    grid-column-gap: 25px;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0;

  ${up('md')} {
    font-size: 28px;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  line-height: 24px;
  letter-spacing: 0;
`;

const NewsletterForm = styled.form`
  margin: 0;
`;

const IntermediateSectionText = css`
  ${Title}, ${Description} {
    color: ${({ theme }) => theme.colors.gray} !important;
  }
`;

const SignatureContainer = styled.div`
  ${({ variant }) => variant === 'intermediate' && IntermediateSectionText};
`;

const Newsletter = ({ variant = 'dark' }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSubmit, errors, control } = useForm();

  const { colors } = useTheme();

  const [openSuccessSnackbar] = useSnackbar(successSnackbar(colors));
  const [openErrorSnackbar] = useSnackbar(errorSnackbar(colors));

  const onSubmit = async ({ newsletterUserEmail: email }) => {
    try {
      setIsSubmitting(true);

      const response = await fetch(NEWSLETTER_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });

      const { data: message } = await response.json();

      openSuccessSnackbar(message);
    } catch (err) {
      openErrorSnackbar(err.message);
    }
    setIsSubmitting(false);
  };

  return (
    <Section.Container id="" variant={variant} disableVerticalMargins>
      <Container>
        <NewsletterContainer>
          <TextContainer>
            <ImageContainer>
              <Image filename="newsletter/email.png" alt="Email icon" />
            </ImageContainer>
            <SignatureContainer variant={variant}>
              <Title>Receba notícias exclusivas</Title>
              <Description>assinando a nossa newsletter</Description>
            </SignatureContainer>
          </TextContainer>
          <NewsletterForm onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <Controller
                id="newsletterUserEmail"
                type="email"
                title="Digite seu e-mail"
                name="newsletterUserEmail"
                error={errors.newsletterUserEmail}
                as={TextInput}
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  maxLength: 150,
                  validate: (value) => isEmail(value),
                }}
              />
              <Button
                primary
                small
                aria-label="confirm-newsletter-subscription-button"
                disabled={isSubmitting === true}
              >
                {isSubmitting === true ? (
                  <PuffLoader color={colors.white} size={20} />
                ) : (
                  'Salvar'
                )}
              </Button>
            </InputContainer>
            <ErrorMessage error={errors.newsletterUserEmail} />
          </NewsletterForm>
        </NewsletterContainer>
      </Container>
    </Section.Container>
  );
};

export default Newsletter;
