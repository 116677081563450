import styled, { css } from 'styled-components';

const ActiveLink = css`
  &:after {
    width: 6px;
  }
`;

const LinkAnimated = styled.span`
  text-decoration: none;
  position: relative;
  margin-bottom: 0;
  transition: 0.4s;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.blue : theme.colors.gray};
  font-weight: ${(props) =>
    props.selected ? props.theme.fontWeights.bold : 400};
  font-family: ${(props) => props.theme.fonts.primary};
  cursor: pointer;
  vertical-align: middle;
  min-height: 50px;
  line-height: 50px;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    transition: width 0.5s ease, background-color 0.5s ease;
    left: calc(50% - 3px);
    bottom: 0;
    background: ${({ selected, theme }) =>
      selected ? theme.colors.blue : 'none'};
  }

  ${({ active }) => active && ActiveLink};
`;

export default LinkAnimated;
