import styled, { css } from 'styled-components';

const PrimaryButton = css`
  background: ${({ theme }) => theme.colors.button.primary.background};
  color: ${({ theme }) => theme.colors.button.primary.text};
`;

const SecondaryButton = css`
  background: ${({ theme }) => theme.colors.button.secondary.background};
  color: ${({ theme }) => theme.colors.button.secondary.text};
`;

const QuietButton = css`
  background: ${({ theme }) => theme.colors.button.quiet.background};
  color: ${({ theme }) => theme.colors.button.quiet.text};
`;

const SmallVariant = css`
  font-size: 16px;
  padding: 6px 15px;
`;

export const Button = styled.button`
  letter-spacing: 0px;
  outline: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 8px 28px;
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 600;
  box-shadow: 0px 2px 5px #00000029;

  ${({ primary }) => primary && PrimaryButton};
  ${({ secondary }) => secondary && SecondaryButton};
  ${({ quiet }) => quiet && QuietButton};

  ${({ small }) => small && SmallVariant};
`;
