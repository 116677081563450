import React from "react";
import Section from "../components/Section";
import styled from "styled-components";
import { Container } from "../components/Container";
import FeatureBox from "../components/FeatureBox/FeatureBox";
import { up } from "styled-breakpoints";

const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;

  ${up("lg")} {
    grid-template-columns: repeat(2, 260px);
    grid-column-gap: 60px;
    justify-content: center;
  }
`;

const FeaturesSection = () => {
  const features = [
    {
      title: "Consultar processo",
      description:
        "Pelo portal você poderá acompanhar o andamento relativo ao seu processo.",
      image: {
        url: "features/thumb2.png",
      },
    },
    {
      title: "Recuperar crédito",
      description:
        "Você recupera o crédito indevido mediante adesão a ação coletiva do sindicado da sua categoria.",
      image: {
        url: "features/thumb3.png",
      },
    },
  ];
  return (
    <Section.Container id="Funcionalidades">
      <Container>
        <Section.Header name="Veja outras vantagens de se associar:"></Section.Header>
        <FeaturesList>
          {features.map((feature, index) => (
            <FeatureBox
              key={index}
              title={feature.title}
              description={feature.description}
              imagePath={feature.image.url}
            />
          ))}
        </FeaturesList>
      </Container>
    </Section.Container>
  );
};

export default FeaturesSection;
