import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import { Container } from "../components/Container";
import Image from "../components/Image";

const FooterBox = styled.div`
  background: ${({ theme }) => theme.colors.footer.background} 0% 0% no-repeat
    padding-box;
  padding: 20px 0px;
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  ${up("lg")} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterText = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.footer.text};
  text-align: center;
`;

const SocialNetworksBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;

  ${up("lg")} {
    margin-top: 0;
  }

  a {
    width: 30px;
    height: 30px;
    margin: 0px 15px;

    &:last-child {
      margin: none !important;
    }
  }
`;

const SocialNetworkCircle = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gray};
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  padding: 7px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`;

const Footer = () => (
  <FooterBox as="footer">
    <Container>
      <FooterContainer>
        {/* <Slide bottom> */}
        <FooterText>
          {`Colaborador Online @${new Date().getFullYear()} | All rights reserved.`}
        </FooterText>
        {/* </Slide> */}
        {/* <Fade bottom> */}
        <SocialNetworksBox>
          <a href="/" aria-label="YouTube Link" target="_blank" rel="noopener">
            <SocialNetworkCircle>
              <Image
                filename="social/logo-youtube.png"
                alt="YouTube logo"
                height="12.93px"
              />
            </SocialNetworkCircle>
          </a>
          <a href="/" aria-label="Facebook Link" target="_blank" rel="noopener">
            <SocialNetworkCircle>
              <Image
                filename="social/logo-facebook.png"
                alt="Facebook logo"
                height="12.93px"
              />
            </SocialNetworkCircle>
          </a>
          <a
            href="/"
            aria-label="Instagram Link"
            target="_blank"
            rel="noopener"
          >
            <SocialNetworkCircle>
              <Image
                filename="social/logo-instagram.png"
                alt="Instagram logo"
                height="15.3px"
              />
            </SocialNetworkCircle>
          </a>
        </SocialNetworksBox>
        {/* </Fade> */}
      </FooterContainer>
    </Container>
  </FooterBox>
);

export default Footer;
