import React from 'react';
import { Reveal } from 'react-reveal';
import { ErrorMessageBox } from './SharedStyles';

export const ErrorMessage = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Reveal>
      <ErrorMessageBox>
        {error.type === 'required' && 'Este campo é obrigatório'}
        {error.type === 'maxLength' && 'Limite de caractéres excedido'}
        {error.type === 'validate' && 'Verifique o valor inserido'}
      </ErrorMessageBox>
    </Reveal>
  );
};
