import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Image from '../Image';
import { up } from 'styled-breakpoints';

const ClientBox = styled.div`
  display: grid;
  width: 100%;
  max-width: 720px;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin: 0 auto;
  align-items: center;

  ${up('md')} {
    grid-template-columns: 200px 1fr;
    grid-column-gap: 40px;
  }
`;

const ClientImageContainer = styled.div`
  width: 65px;

  ${up('md')} {
    width: 200px;
  }

  ${up('lg')} {
    width: 100%;
  }
`;

const TestimonialText = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
`;

const TestimonialAuthor = styled.h3`
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const ClientTestimonialBox = styled.div`
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #0000004d;
  padding: 32px;
  border-radius: 4px;

  position: relative;
  display: inline-block !important;

  &:after {
    content: '';
    position: absolute;
    top: -11px;
    left: 20.5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent ${({ theme }) => theme.colors.white}
      transparent;

    ${up('md')} {
      border-width: 12px 20px 12px 0;
      border-color: transparent ${({ theme }) => theme.colors.white} transparent
        transparent;
      top: 50%;
      transform: translateY(-50%);
      left: -19px;
    }
  }

  ${up('lg')} {
    margin-left: 20px;

    &:after {
      display: block;
    }
  }

  ${TestimonialText} {
    margin-bottom: 20px;
  }
`;

const StepsBox = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, 40px);
  justify-content: center;
  grid-column-gap: 10px;
  list-style-type: none;
`;

const ActiveStep = css`
  background: ${({ theme }) => theme.colors.blue};
`;

const Step = styled.li`
  width: 100%;
  height: 4px;
  list-style-type: none;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer !important;
  ${({ isActive = false }) => isActive && ActiveStep};
`;

const TestimonialsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  grid-row-gap: 44px;
`;

const Testimonials = ({ testimonials }) => {
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[0]);

  const [active, setActive] = useState(0);

  const handleSetClick = (activeItemNumber) => {
    setCurrentTestimonial(testimonials[activeItemNumber]);
    setActive(activeItemNumber);
  };

  return (
    <TestimonialsContainer>
      <ClientBox>
        <ClientImageContainer>
          <Image
            filename={currentTestimonial.image.relativePath}
            alt="User Photo"
          />
        </ClientImageContainer>
        <ClientTestimonialBox>
          <TestimonialText>{currentTestimonial.message}</TestimonialText>
          <TestimonialAuthor>{currentTestimonial.client}</TestimonialAuthor>
        </ClientTestimonialBox>
      </ClientBox>

      <StepsBox>
        {Object.keys(testimonials).map((key) => (
          <Step
            onClick={() => handleSetClick(key)}
            data-quote={key}
            key={key}
            isActive={Number(key) === Number(active)}
          />
        ))}
      </StepsBox>
    </TestimonialsContainer>
  );
};

export default Testimonials;
