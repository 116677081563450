import React from 'react';
import Section from '../components/Section';
import styled from 'styled-components';
import { Container } from '../components/Container';
import Testimonials from '../components/Testimonials/Testimonials';

const ClientTestimonialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ClientTestimonials = () => {
  const testimonials = {
    0: {
      client: 'Sidney Barbosa, 68 anos',
      message:
        'Finalmente consegui recuperar crédito depois de 5 anos de tentativas. O site Colaborador Online me deu todas as informações e suporte que eu precisava.',
      image: {
        relativePath: 'testimonials/user-photo.png',
      },
    },
    1: {
      client: 'Ricardo Alvarez, 50 anos',
      message:
        'Eu dolore sunt irure cillum cupidatat officia commodo est cillum aliqua laboris..',
      image: {
        relativePath: 'testimonials/user-photo.png',
      },
    },
    2: {
      client: 'Sydnei Andrade, 32 anos',
      message:
        'Fugiat quis voluptate veniam nulla cupidatat aliquip consequat esse pariatur quis eu labore.',
      image: {
        relativePath: 'testimonials/user-photo.png',
      },
    },
  };

  return (
    <Section.Container id="Depoimentos" variant="dark">
      <Container>
        <Section.Header
          name="Depoimentos dos nossos clientes"
          variant="dark"
        ></Section.Header>
        <ClientTestimonialsContainer>
          <Testimonials testimonials={testimonials} />
        </ClientTestimonialsContainer>
      </Container>
    </Section.Container>
  );
};

export default ClientTestimonials;
