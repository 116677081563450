import React from "react";
import styled from "styled-components";
import Image from "../Image";
import { Button } from "../Button";
import { navigate } from "gatsby";

const Box = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 180px;
  border-bottom: 3px solid
    ${({ theme, blueStroke }) =>
      blueStroke ? theme.colors.blue : theme.colors.lightGray};
  padding-bottom: 30px;
  margin-bottom: 30px;
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const Description = styled.p`
  max-width: 250px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const FeatureBox = ({
  title,
  description,
  imagePath,
  href,
  blueStroke = false,
}) => {
  return (
    <Box>
      <ImageContainer blueStroke={blueStroke}>
        <Image filename={imagePath} alt="Feature image" />
      </ImageContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>

      {href ? (
        <Button primary small onClick={() => navigate("/create-account")}>
          Criar conta
        </Button>
      ) : null}
    </Box>
  );
};

export default FeatureBox;
