const isDev = false;

const BASE_API_URL = isDev
  ? "https://api-dev.colaboradoronline.com.br"
  : "https://api-prd.colaboradoronline.com.br";

export const FRONT_END_PORTAL_URL = isDev
  ? "http://dev-portal.colaboradoronline.com.br"
  : "http://portal.colaboradoronline.com.br";

export const FRONT_END_COMPLETE_REGISTRATION_URL = `${FRONT_END_PORTAL_URL}/confirm-syndicate`;

// TODO: Check the right newsletter URL.
export const NEWSLETTER_URL = `${BASE_API_URL}/newsletter`;

export const GET_STATES = `${BASE_API_URL}/administration-state/v1/list`;

export const GET_CITIES_BY_STATE = `${BASE_API_URL}/administration-city/v1/list-by-stateid`;

export const GET_SYNDICATES_BY_CITY_ID = `${BASE_API_URL}/administration-syndicate/v1/list-by-cityid`;

export const GET_SYNDICATE_DETAILS = `${BASE_API_URL}/administration-syndicate/v1/details`;

export const CONTACT_URL = `${BASE_API_URL}/contact/v1/register-contact-web`;
